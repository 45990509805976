const products = [
    {
        name: "armchair", 
        code: "ZX5MV597NN",
        hasFabric: true,
        hasWood: true,
        hasPiano: true,
    },
    {
        name: "bookshelf", 
        code: "OOBQKI8610",
        hasFabric: true,
        hasWood: true,
        hasPiano: false,
    },
];

export default products