import tile_fenice from './assets/tiles/fenice510.png';
import tile_limonta from './assets/tiles/limonta.png';
import tile_newclassic from './assets/tiles/newclassic.png';
import tile_pugi from './assets/tiles/pugi.png';
import tile_urbanshadow from './assets/tiles/urbanshadow.png';

const materiali = [
    {
        collection: '',
        items: [
          {
            slug: "fenice 510",
            name: "fenice 510",
            thumbnail: tile_fenice
          },
          {
              slug: "limonta pepe plus 115",
              name: "limonta pepe plus 115",
              thumbnail: tile_limonta
          },
          {
              slug: "new classic 856 t16",
              name: "new classic 856 t16",
              thumbnail: tile_newclassic
          },
          {
              slug: "pugi 205",
              name: "pugi 205",
              thumbnail: tile_pugi
          },
          {
              slug: "urban shades 166",
              name: "urban shades 166",
              thumbnail: tile_urbanshadow
          },
        ]
    }
];

export default materiali