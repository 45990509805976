import pelle_nera from '../assets/tiles/pelle_nera.png';
import pelle_marrone from '../assets/tiles/pelle_marrone.png';

const inserti = [
{
    category: 'cat. A',
    collection: '',
    items: [
      {
        slug: "pelle marrone",
        name: "pelle marrone",
        thumbnail: pelle_marrone
      },
      {
          slug: "pelle nera",
          name: "pelle nera",
          thumbnail: pelle_nera
      }
    ]
  }
]

export default inserti;