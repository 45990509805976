import React, { useEffect, useState } from 'react';
import Player from './Player';
import { Accordion } from './Accordion';
import { material, defaultRivestimento, defaultStruttura, defaultFinitura, switchConfiguration } from './config';
import struttura from './collections/struttura';
import inserti from './collections/inserti';
import products from './products';
import logo from './assets/logo-dieffebi.svg';
import FamilyAccordion from './FamilyAccordion';

function App({ productId }) {
  const [loading, setLoading] = useState(true);
  const [selectedProductCode, setSelectedProductCode] = useState(productId);
  const [selectedMaterial, setSelectedMaterial] = useState(defaultRivestimento);
  const [selectedFamily, setSelectedFamily] = useState(null);
  const [selectedRivestimento, setSelectedRivestimento] = useState(defaultRivestimento);
  const [selectedStruttura, setSelectedStruttura] = useState(defaultStruttura);
  const [selectedFinitura, setSelectedFinitura] = useState(defaultFinitura);
  const [isAccordionHidden, setIsAccordionHidden] = useState(false);
  const [configurableMaterialOnClick, setConfigurableMaterialOnClick] = useState(null);

  const toggleAccordionVisibility = () => {
    setIsAccordionHidden((prevState) => !prevState);
    setTimeout(()=>{
      emViewers['emersyaIframe'].resize()

    },(150))
  };
  

  const handleFamilyChange = (newFamilyName) => {
    setSelectedFamily(newFamilyName);
  };
   // Get the first product if found
  const selectedProduct = products.find((product) => product.code === selectedProductCode) || {};

  //setSelectedFamily(selectedProduct.family);

  const setDefaultMaterials = (projectData) => {
    // Set default rivestimento
    const defaultRivestimentoSlug = projectData.materialTrees.finitura;
    const defaultRivestimentoMaterial = material.flatMap((collection) => collection.items).find((item) => item.slug === defaultRivestimentoSlug);
    if (defaultRivestimentoMaterial) {
      defaultRivestimento.slug = defaultRivestimentoSlug;
      defaultRivestimento.name = defaultRivestimentoMaterial.name;
      defaultRivestimento.thumbnail = defaultRivestimentoMaterial.thumbnail;
      
    }

    // Set default struttura
    const defaultStrutturaSlug = projectData.materialTrees.struttura;
    const defaultStrutturaMaterial = struttura.flatMap((collection) => collection.items).find((item) => item.slug === defaultStrutturaSlug);
    if (defaultStrutturaMaterial) {
      defaultStruttura.slug = defaultStrutturaSlug;
      defaultStruttura.name = defaultStrutturaMaterial.name;
      defaultStruttura.thumbnail = defaultStrutturaMaterial.thumbnail;

    }

    // Set default finitura
    const defaultFinituraSlug = projectData.materialTrees.inserti;
    const defaultFinituraMaterial = inserti.flatMap((collection) => collection.items).find((item) => item.slug === defaultFinituraSlug);
    if (defaultFinituraMaterial) {
      defaultFinitura.slug = defaultFinituraSlug;
      defaultFinitura.name = defaultFinituraMaterial.name;
      defaultFinitura.thumbnail = defaultFinituraMaterial.thumbnail;
    }
    
  setLoading(false);
  };

  useEffect(() => {
    function initializeEmersyaAPI() {
      const emersyaViewer = emViewers['emersyaIframe'];
    
      let configurableMaterialOnClick = null;

      emersyaViewer.addEventListener('onConfigurableMaterialHighlight', function(event) {
        // Check if any mesh is highlighted
        if (event[0] && event[0].indexOf('imbottito_') === 0) {
          configurableMaterialOnClick = event[0];
          
        } else {
          configurableMaterialOnClick = null;
        }
      });
      
  
      emersyaViewer.addEventListener('onStateChange', (data) => {
        if (data.viewerState === 'loaded') {
          emersyaViewer.getCurrentGlobalConfiguration().then((data) => {
            const currentProject = Object.keys(data.projectsData)[0];
            const projectData = data.projectsData[currentProject];
            setDefaultMaterials(projectData);
            
            console.log(projectData)
           
          });
        }
      });
    }

    document.addEventListener('emersyaViewerInitialized', initializeEmersyaAPI, false);

    // Cleanup function
    return () => {
      document.removeEventListener('emersyaViewerInitialized', initializeEmersyaAPI);
    };
  }, []);

  const handleProductChange = (newProductCode) => {
    if (!newProductCode) {
      return; // If the selected value is empty, do nothing
    }
  
    setLoading(true);
    setSelectedProductCode(newProductCode);
    const newProduct = products.find((product) => product.code === newProductCode);
    if (newProduct) {
      
      setSelectedRivestimento(defaultRivestimento);
      setSelectedStruttura(defaultStruttura);
      setSelectedFinitura(defaultFinitura);
      setConfigurableMaterialOnClick(null)
    }
  };

  const resetRivestimentoMaterial = () => {
    setSelectedMaterial(defaultRivestimento);
  };

  const handleMaterialChange = (newMaterial) => {
    if (configurableMaterialOnClick != null) {
      setSelectedRivestimento(newMaterial);
      switchConfiguration(newMaterial, configurableMaterialOnClick);
    } else {
      setSelectedRivestimento(newMaterial);
      switchConfiguration(newMaterial, 'finitura');
    }
    emViewers['emersyaIframe'].setHighlight({ configurableMaterials: [''] });
  };
  
  const handleWoodChange = (newMaterial) => {
    setSelectedStruttura(newMaterial);
    switchConfiguration(newMaterial, 'struttura');
  };
  
  const handleFinituraChange = (newMaterial) => {
    setSelectedFinitura(newMaterial);
    switchConfiguration(newMaterial, 'inserti');
  };

return   <>
    {loading && (
      <div className="ay-w-full ay-h-screen ay-bg-white ay-opacity-95 ay-fixed ay-top-0 ay-z-50 ay-flex ay-justify-center ay-items-center ay-text-3xl" id="loader">
        <img src={logo} alt="Logo dieffebi" className="ay-animate-scale-pulse" />
      </div>
    )}

<div className="ay-flex ay-flex-col md:ay-flex-row ay-h-screen ay-overflow-hidden">
  <div className={`ay-relative  ${isAccordionHidden ? 'ay-w-full' : 'ay-w-full md:ay-w-4/5'} ay-transition-all duration-500 ay-ease-in-out`}>
  
      <Player selectedProductCode={selectedProductCode} />
      
    
    <button
      onClick={toggleAccordionVisibility}
      className="ay-absolute ay-right-4 ay-opacity-20 ay-top-1/2 ay-transform ay--translate-y-1/2 ay-text-3xl ay-font-bold ay-focus:ay-outline-none"
    >
      {isAccordionHidden ? '<' : '>'}
    </button>
  </div>
  <div
    className={`ay-p-5 ay-bg-[#f9f9f9] ay-overflow-auto ${isAccordionHidden ? 'ay-w-0' : 'ay-w-full md:ay-w-3/5 lg:ay-w-2/6'} ay-transition-all duration-500 ay-ease-in-out`}
    style={{ display: isAccordionHidden ? 'none' : 'block' }}
  >
    <div className='grid grid-cols-3 ay-h-full'>
      <FamilyAccordion
        products={products}
        selectedProduct={selectedProduct}
        onProductChange={handleProductChange}
        selectedFamily={selectedFamily}
      />

      <div>
        <Accordion
          title="finitura"
          materials={material}
          selected={selectedRivestimento}
          onChange={handleMaterialChange}
          customConfig={
            selectedProduct.customCollection && selectedProduct.customMaterial
              ? {
                  customCollection: selectedProduct.customCollection,
                  customMaterial: selectedProduct.customMaterial,
                }
              : null
          }
          defaultRivestimento={defaultRivestimento}
          type="finitura"
        />
        {selectedProduct.hasWood && (
          <Accordion
            title="struttura"
            materials={struttura}
            selected={selectedStruttura}
            onChange={handleWoodChange}
            customConfig={
              selectedProduct.customMaterial
                ? {
                    customMaterial: selectedProduct.customMaterial,
                  }
                : null
            }
            defaultRivestimento={defaultStruttura}
            type="struttura"
          />
        )}
         {selectedProduct.hasPiano && selectedProduct.hasPiano !== null && (
          <Accordion
            title="inserti"
            materials={inserti}
            selected={selectedFinitura}
            onChange={handleFinituraChange}
            customConfig={
              selectedProduct.customPiano 
                ? {
                    customMaterial: selectedProduct.customPiano,
                  }
                : null
            }
            defaultRivestimento={defaultFinitura}
            type="inserti"
          />
        )}
      </div>
    </div>
    
  </div>
</div>
</>

}

export default App