// config.js
import materiali from './materials-stuff.js';

export const material = materiali;
export const defaultRivestimento = {
  slug: '',
  name: '',
  thumbnail: '',
};

// If found, set defaultStruttura to the material object
export const defaultStruttura =  {
  slug: '',
  name: '',
  thumbnail: '',
};

export let defaultFinitura =  {
  slug: '',
  name: '',
  thumbnail: '',
};

export let configuration = {
  rivestimento: defaultRivestimento.slug,
};
export let appliedConfiguration = `${configuration.rivestimento}`;

export function switchConfiguration(configurationName, type) {
  let configurableMaterial = null
  if (type === 'finitura') {
    configuration.rivestimento = configurationName;
    configurableMaterial = 'finitura'
    appliedConfiguration = `${configuration.rivestimento.slug}`
  } else if (type === 'struttura') {
    configuration.struttura = configurationName;
    configurableMaterial = 'struttura'
    appliedConfiguration = `${configuration.struttura.slug}`

  } else if (type === 'inserti') {
    configuration.finitura = configurationName;
    configurableMaterial = 'inserti'
    appliedConfiguration = `${configuration.finitura.slug}`

  } else if (/^imbottito_/.test(type)) {
    configuration.rivestimento = configurationName;
    configurableMaterial = type
    appliedConfiguration = `${configuration.rivestimento.slug}`
  }

    // Update appliedConfiguration with configuration values
/* 
  console.log(configuration) 
  console.log(type);
 */
  emViewers['emersyaIframe'].setMaterials({
    materials: [{ materialVariation: appliedConfiguration, configurableMaterial }],
  })
}

