import tile_marrone from '../assets/tiles/marrone_ocra.png';
import tile_giallo from '../assets/tiles/giallo_curry.png';
import tile_blu from '../assets/tiles/blu_zaffiro.png';
import tile_mdf from '../assets/tiles/mdf_antracite.png';
import tile_honey from '../assets/tiles/honey_yellow.png';

const struttura = [
{
    category: 'cat. A',
    collection: '',
    items: [
      {
        slug: "marrone ocra ral 8001",
        name: "marrone ocra ral 8001",
        thumbnail: tile_marrone
      },
      {
          slug: "giallo curry ral 1027",
          name: "giallo curry ral 1027",
          thumbnail: tile_giallo
      },
      {
          slug: "blu zaffiro ral 5003",
          name: "blu zaffiro ral 5003",
          thumbnail: tile_blu
      },
      {
          slug: "mdf antracite ez2",
          name: "mdf antracite ez2",
          thumbnail: tile_mdf
      },
      {
          slug: "honey yellow ral 1005",
          name: "honey yellow ral 1005",
          thumbnail: tile_honey
      },
    ]
  }
]

export default struttura;